/**
 * NVseStandardListingPage - styles
 */

import styled from 'styled-components';
import { mediaQueries } from 'theme';
import { accessibilityFocus } from 'theme/styles';
import { px2rem } from 'theme/utils';

type IsRegulationPageProps = {
	isRegulationPage?: boolean;
};
export const ClearAllLinkContainer = styled.div`
	${(props) => props.theme.typography.style4.getStyle()};

	padding-left: ${(props) => props.theme.spacing.space4}rem;
	${mediaQueries.phone} {
		padding-left: ${(props) => props.theme.spacing.space2}rem;
	}
	h4 {
		margin-bottom: ${px2rem(8)};
		margin-top: ${px2rem(16)};
	}
	span {
		line-height: ${px2rem(48)};
		&:hover {
			text-decoration: underline;
			cursor: pointer;
		}
		&:focus {
			text-decoration: underline;
			${accessibilityFocus()};
		}
		color: ${(props) => props.theme.colors.secondaryBlue};
	}
`;

export const IndexLabel = styled.h2`
	${(props) => props.theme.typography.style2.getStyle()};
`;

export const TotalCountDisplay = styled.div<IsRegulationPageProps>`
	margin-top: ${(props) => props.theme.spacing.space2}rem;
	padding-left: ${(props) => props.theme.spacing.space4}rem;
	color: ${(props) => props.theme.colors.primary};
	${mediaQueries.phone} {
		padding-left: ${(props) =>
			props.isRegulationPage ? 0 : props.theme.spacing.space2}rem;
		margin-top: ${(props) => props.theme.spacing.space1}rem;
	}
	h4 {
		margin-bottom: 0;
		${mediaQueries.phone} {
			${(props) =>
				props.isRegulationPage
					? props.theme.typography.style2.getStyle()
					: props.theme.typography.style4.getStyle()}
		}
	}
`;
